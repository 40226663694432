import React from "react"
import { graphql, Link } from "gatsby"
import Reactmarkdown from "react-markdown"

import Layout from "../components/layout"
import SEO from "../components/seo"
import HeaderProjekte from "../components/header_projekte"
import Carousel from "../components/carousel"

const ProjectTemplate = ({ data }) => {
  const project = data.strapiProject

  return (
    <Layout>
    <SEO title={project.title} />
    <HeaderProjekte />

    <Carousel images={ project.pictures } />
    <Reactmarkdown>{ project.description }</Reactmarkdown>

  </Layout>
  )
}

export default ProjectTemplate

export const query = graphql`
  query ProjectQuery($slug: String!) {
    strapiProject(slug: {eq: $slug}) {
      title
      description
      pictures {
        localFile {
          childImageSharp {
            fixed(height: 550) {
              ...GatsbyImageSharpFixed
            }
          }
        }
      } 
    }
  }
`